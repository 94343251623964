import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";

import { ReactComponent as SvgClose } from "../../images/close.svg";
import { formatDateFull } from "../../shared/utils/utils";
import { usePartnersStore } from "../../store/PartnersStore";

const LogModal = ({ closeModal, selectedPartId }) => {
  const { t } = useTranslation("partners");
  const partnersStore = usePartnersStore();
  const [balanceHistory, setBalanceHistory] = useState(null);

  useEffect(() => {
    partnersStore
      .fetchFuelHistory(selectedPartId)
      .then((res) => {
        if (res && res.transferHistory) {
          setBalanceHistory(res.transferHistory);
        } else {
          setBalanceHistory([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching fuel history:", error);
        setBalanceHistory([]);
      });
  }, []);

  return (
    <div className="modal-history m-partner-history">
      <div className="window_close" onClick={closeModal}>
        <SvgClose />
      </div>
      <div className="m-history-title">{t("history")}</div>

      <SimpleBar className="m-history-content">
        <div className="content-table">
          <div className="table-th">
            <div>{t("date")}</div>
            <div style={{ textAlign: "left" }}>{t("fuel")}</div>
          </div>
          {/* TODO вынести в отдельные стили*/}
          {balanceHistory?.map((item) => (
            <div key={item.date} className="table-tr">
              <div>{formatDateFull(new Date(item.date * 1000))}</div>
              <div style={{ textAlign: "left", paddingLeft: "10px" }}>
                {item.fuel?.toLocaleString()}
              </div>
            </div>
          ))}
        </div>
      </SimpleBar>
    </div>
  );
};

export default LogModal;
