import { useMemo } from "react";

import { selectBotLabel } from "../../shared/utils/utils";
import { useConfigStore } from "../../store/ConfigStore";

export function useBotModeOptions() {
  const allowedRooms = useConfigStore((config) => config?.allowedRooms);
  const selectedRoom = useConfigStore((config) => config?.selectedRoom);
  const selectedRoomType = useConfigStore((config) => config?.selectedRoomType);
  return useMemo(() => {
    if (!allowedRooms || !selectedRoom || !selectedRoomType) {
      return [];
    }
    return [
      ...Object.entries(allowedRooms[selectedRoom][selectedRoomType] ?? {})
        .map((item) => {
          const botMode = item[1];
          if (!botMode || !botMode.name) {
            return null;
          }
          return {
            value: item[0],
            label: selectBotLabel(botMode.name),
            hint: botMode.priceAdd > 0 ? botMode.priceAdd : null,
          };
        })
        .filter(Boolean),
    ];
  }, [allowedRooms, selectedRoomType, selectedRoom]);
}
