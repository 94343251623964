import React from "react";

import { useSessionsDefaultFiltersOptions } from "../../../entities/session/use-sessions-default-filters-options";
import { FilterDatePicker } from "../../../shared/ui/filter-date-picker";
import { useSessionPageStore } from "../../../store/session-page-store";

export function SessionsDateStarted({ ...props }) {
  const defaults = useSessionsDefaultFiltersOptions();
  const { searchParams, setSearchParam, setSearchParams } = useSessionPageStore();
  return (
    <FilterDatePicker
      // defaultValue={defaults?.dateStarted?.option}
      placeholderText="Date from"
      id="dateStarted"
      name="dateStarted"
      searchParams={searchParams}
      setSearchParam={setSearchParam}
      setSearchParams={setSearchParams}
      {...props}
    />
  );
}
