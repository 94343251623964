import { css } from "@emotion/react";
import React from "react";

export function TempInputLabel({ children, errorMessage, disabled, ...props }) {
  const hasError = errorMessage && !disabled;
  return (
    <label
      css={css`
        &&& {
          flex-direction: column;
          input {
            border: var(${hasError ? "--input-border-error" : "--input-border"});
          }
        }
      `}
      {...props}
    >
      {children}
      {hasError && (
        <div
          css={css`
            font-size: 11px;
            line-height: 24px;
            color: red;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: left;
          `}
        >
          {errorMessage}
        </div>
      )}
    </label>
  );
}
