import React, { useState } from "react";

import { ReactComponent as SvgClose } from "../../images/close.svg";
import { ReactComponent as SvgRecode } from "../../images/re_code.svg";

const ResetModal = ({ closeModal, onReset }) => {
  return (
    <div className="modal-inner-mini-q">
      <div className="window_close" onClick={closeModal}>
        <SvgClose />
      </div>
      <div className="modal-title-q">Do you want reissue the key code?</div>
      <div className="modal-icon-q">
        <SvgRecode />
      </div>
      <div onClick={onReset} className="p-btn">
        Reset Code
      </div>
    </div>
  );
};

export default ResetModal;
