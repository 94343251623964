import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as SvgClose } from "../../images/close.svg";
import { useHeaderStore } from "../../store/HeaderStore";
import { usePartnersStore } from "../../store/PartnersStore";
import { useSessionStore } from "../../store/SessionStore";

const TopUpModal = ({
  closeModal,
  selectedPartId,
  partnerBalance,
  selectedPartnerName,
}) => {
  const { t } = useTranslation("partners");
  const currentUser = useSessionStore().currentUser;
  const headerStore = useHeaderStore();
  const partnersStore = usePartnersStore();
  const myBalance = headerStore.balance;
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    const value = event.target.value;
    // Проверяем, что введенное значение является числом
    if (!isNaN(value)) {
      setInputValue(value);
    }
  };

  const shakeModal = () => {
    const modalElement = document.querySelector(".modal");
    let startX = 0;
    const amplitude = 25;
    const duration = 300;
    const startTime = performance.now();

    const step = (timestamp) => {
      const progress = timestamp - startTime;
      const deltaX = amplitude * Math.sin((progress / duration) * (Math.PI * 2));
      modalElement.style.transform = `translateX(${deltaX}px)`;

      if (progress < duration) {
        requestAnimationFrame(step);
      } else {
        modalElement.style.transform = "translateX(0)";
      }
    };

    requestAnimationFrame(step);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const inputValueAsNumber = parseFloat(inputValue);
    if (inputValueAsNumber > myBalance) {
      setInputValue(myBalance.toString());
      shakeModal();
    } else {
      partnersStore
        .transferFuel(currentUser, selectedPartId, inputValue)
        .then(() => {
          partnersStore.fetchPartners();
          headerStore.fetchBalance();
          closeModal();
        });
    }
  };

  return (
    <div className="modal-fuel">
      <div className="m-fuel-title">
        {t("topUp")}
        <div className="window_close" onClick={closeModal}>
          <SvgClose />
        </div>
      </div>
      <div className="m-fuel-partner">{selectedPartnerName}</div>
      <div className="my-balance">
        <span>{t("myBalance")}</span>
        <span>{myBalance}</span>
      </div>
      <div className="partner-balance">
        <span>{t("partnerBalance")}</span>
        <span>{partnerBalance}</span>
      </div>
      <div className="m-fuel-input">
        <input
          type="text"
          className="st-in"
          value={inputValue}
          placeholder={t("fuelAmount")}
          onChange={handleInputChange}
        />
        <div className="fuel-go" onClick={handleFormSubmit}>
          {t("topUp")}
        </div>
      </div>
    </div>
  );
};

export default TopUpModal;
