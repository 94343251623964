import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const LangSwitch = () => {
  const { i18n, t } = useTranslation("header");

  let currentLanguage = i18n.language;
  const [isChMode, setIsChMode] = useState(currentLanguage === "zh");

  const handleLanguageToggle = () => {
    setIsChMode(!isChMode);
    i18n.changeLanguage(currentLanguage === "en" ? "zh" : "en");
  };

  return (
    <label style={{ paddingBottom: 5 }}>
      <span>{t("cn")}</span>
      <input
        type="checkbox"
        className="on-off"
        checked={isChMode}
        onChange={handleLanguageToggle}
      />
    </label>
  );
};

export default LangSwitch;
