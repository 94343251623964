import React, { useCallback, useMemo } from "react";

import Select from "../../components/Select";

export function FilterSelect({
  options = [],
  name,
  resetOthers = false,
  defaultValue,
  searchParams,
  setSearchParam,
  setSearchParams,
  onSelect,
  ...props
}) {
  const storeValue = useMemo(() => searchParams?.[name], [name, searchParams]);

  const selectedItem = useMemo(() => {
    return options?.find((option) => option?.value === storeValue) || defaultValue;
  }, [defaultValue, options, storeValue]);

  const $onSelect = useCallback(
    (option) => {
      if (resetOthers) {
        setSearchParams({
          [name]: option?.value,
        });
        return;
      }
      setSearchParam(name, option?.value);
      if (typeof onSelect === "function") {
        onSelect?.(option);
      }
    },
    [name, onSelect, resetOthers, setSearchParam, setSearchParams]
  );

  return (
    <Select
      gray
      options={options}
      onSelect={$onSelect}
      selected={selectedItem}
      {...props}
    />
  );
}
