import { useCallback } from "react";
import { toast } from "react-toastify";

import { useSessionStore } from "../../store/SessionStore";

export function useUserLogOut() {
  const { logout, isLoggedIn } = useSessionStore();
  return useCallback(
    async ({ message, messageDelay = 100, messageAutoClose = 5000 } = {}) => {
      try {
        if ((await logout()) === "SUCCESS") {
          if (message) {
            toast.warn(message, {
              delay: messageDelay,
              autoClose: messageAutoClose,
              position: "top-center",
            });
          }
          await isLoggedIn();
        }
      } catch (err) {
        //
      }
    },
    [isLoggedIn, logout]
  );
}
