import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as SvgClose } from "../../images/close.svg";
import { ReactComponent as SvgEnergy } from "../../images/energy.svg";
import { ReactComponent as SvgGuideApk } from "../../images/guide-apk.svg";
import { ReactComponent as SvgGuideCh } from "../../images/guide-ch.svg";
import { ReactComponent as SvgGuideDark } from "../../images/guide-dark.svg";
import { ReactComponent as SvgGuideLogout } from "../../images/guide-logout.svg";
import { ReactComponent as SvgGuideOptions } from "../../images/guide-options.svg";
import { ReactComponent as SvgGuideRun } from "../../images/guide-run.svg";
import { ReactComponent as SvgGuideSelect } from "../../images/guide-select.svg";
import { ReactComponent as SvgGuideTg } from "../../images/guide-tg.svg";
import { ReactComponent as SvgHistory } from "../../images/history.svg";
import { ReactComponent as SvgSettings } from "../../images/settings.svg";
import { ReactComponent as SvgUser } from "../../images/user.svg";
import { useSessionStore } from "../../store/SessionStore";

const GuideModal = ({
  closeGuideModal,
  closeModal,
  openModal,
  setIsUserMenuOpen,
}) => {
  const { t } = useTranslation("guide");

  const titles = [
    [null],
    [
      t("balance"),
      [
        <div className="g-sl">
          <div className="g-desc">
            <p>{t("toReplenishFuelPressThisButton")}</p>
            <p>
              {t("getReadyToReplenishTheBalanceThroughACryptoWalletInCurrencyUSDT")}
            </p>
          </div>
          <div className="g-icon">
            <div className="p-btn">
              <SvgEnergy />
            </div>
          </div>
        </div>,
        <div className="g-sl">
          <div className="g-desc">
            <p>{t("inThisWindowYouSpecifyTheTopUpAmount")}</p>
            <p>{t("whenYouClickOnTheGiftAddButton")}</p>
          </div>
        </div>,
      ],
    ],
    [
      t("history"),
      [
        <div className="g-sl">
          <div className="g-desc">
            <p>
              {t("pleaseClickOnThisButtonToViewTheTopUpHistoryAndTransferHistory")}
            </p>
          </div>
          <div className="g-icon">
            <div className="p-btn">
              <SvgHistory />
            </div>
          </div>
        </div>,
      ],
    ],
    [
      t("admin"),
      [
        <div className="g-sl">
          <div className="g-desc">
            <p>{t("byClickingOnThisButtonYouCanChangeTheAdministratorSettings")}</p>
          </div>
          <div className="g-icon">
            <div className="p-btn">
              <SvgUser />
            </div>
          </div>
        </div>,
        <div className="g-sl">
          <div className="g-desc">
            <div className="g-desc-item">
              <div className="g-img">
                <SvgGuideDark />
              </div>
              <div className="g-text">{t("switchBetweenLightAndDarkMode")}</div>
            </div>
            <div className="g-desc-item">
              <div className="g-img">
                <SvgGuideCh />
              </div>
              <div className="g-text">{t("changeTheLanguage")}</div>
            </div>
            <div className="g-desc-item">
              <div className="g-img g-img-sh">
                <SvgGuideApk />
              </div>
              <div className="g-text">{t("downloadTheAPK")}</div>
            </div>
            <div className="g-desc-item">
              <div className="g-img g-img-sh">
                <SvgGuideTg />
              </div>
              <div className="g-text">
                {t("setYourTelegramNicknameInTheAPKForPartners")}
              </div>
            </div>
            <div className="g-desc-item">
              <div className="g-img g-img-sh">
                <SvgGuideRun />
              </div>
              <div className="g-text">{t("startTheGuideReplay")}</div>
            </div>
            <div className="g-desc-item">
              <div className="g-img g-img-sh">
                <SvgGuideLogout />
              </div>
              <div className="g-text">{t("logOutOfYourAccount")}</div>
            </div>
          </div>
        </div>,
      ],
    ],
    [
      t("configuration"),
      [
        <div className="g-sl">
          <div className="g-desc">
            <p>{t("thisWindowIsDesignedForAccountConfiguration")}</p>
          </div>
          <div className="g-icon">
            <div className="p-btn g-settings">
              <SvgSettings />
            </div>
          </div>
        </div>,
      ],
    ],
    [
      t("accounts"),
      [
        <div className="g-sl">
          <div className="g-desc">
            <p>
              {t("accountManagementWindow1")}
              <SvgGuideSelect /> {t("accountManagementWindow2")}{" "}
              <SvgGuideOptions className="inv-svg" />
            </p>
          </div>
          <div className="g-icon">
            <div className="p-btn g-t">{t("accounts")}</div>
          </div>
        </div>,
      ],
    ],
    [
      t("sessions"),
      [
        <div className="g-sl">
          <div className="g-desc">
            <p>{t("inThisWindowYouCanViewYourSessions")}</p>
          </div>
          <div className="g-icon">
            <div className="p-btn g-t">{t("sessions")}</div>
          </div>
        </div>,
      ],
    ],
    [
      t("partners"),
      [
        <div className="g-sl">
          <div className="g-desc">
            <p>{t("forVIPpartners")}</p>
            <p>{t("hereYouWillBeAbleToAddNewPartners")}</p>
          </div>
          <div className="g-icon">
            <div className="p-btn g-t">{t("partners")}</div>
          </div>
        </div>,
      ],
    ],
    [null],
  ];

  const [isCloseButtonVisible, setCloseButtonVisible] = useState(true);
  const role = useSessionStore().role;

  const handleMouseEnter = () => {
    setCloseButtonVisible(false);
  };

  const handleMouseLeave = () => {
    setCloseButtonVisible(true);
  };

  const [activeTitleIndex, setActiveTitleIndex] = useState(1);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleBackTitleClick = () => {
    if (activeTitleIndex > 1) {
      setActiveTitleIndex(activeTitleIndex - 1);
      setActiveSlideIndex(0);
    }
  };

  const handleNextTitleClick = () => {
    if (activeTitleIndex < titles.length - 2) {
      setActiveTitleIndex(activeTitleIndex + 1);
      setActiveSlideIndex(0);
    }
  };

  const handleBackSlideClick = () => {
    if (activeSlideIndex > 0) {
      setActiveSlideIndex(activeSlideIndex - 1);
    } else {
      if (activeTitleIndex > 0) {
        setActiveTitleIndex(activeTitleIndex - 1);
        setActiveSlideIndex(titles[activeTitleIndex - 1][1].length - 1);
      }
    }
  };

  const handleNextSlideClick = () => {
    if (activeSlideIndex < titles[activeTitleIndex][1].length - 1) {
      setActiveSlideIndex(activeSlideIndex + 1);
    } else {
      handleNextTitleClick();
    }
  };

  const getTitleContent = () => titles[activeTitleIndex][1][activeSlideIndex];

  const canGoBackTitle = activeTitleIndex > 0;
  const canGoNextTitle = activeTitleIndex < titles.length - 1;

  const canGoBackSlide =
    activeSlideIndex > 0 || (activeSlideIndex === 0 && activeTitleIndex > 1);
  const canGoNextSlide =
    activeSlideIndex < titles[activeTitleIndex][1].length - 1 ||
    (activeSlideIndex === titles[activeTitleIndex][1].length - 1 &&
      activeTitleIndex < titles.length - 2);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.classList.contains("modal-overlay")) {
        closeGuideModal();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  });

  useEffect(() => {
    return () => {
      closeGuideModal();
    };
  }, []);

  const onAppearFunctions = [
    [],
    [
      () => {
        //Slide Balance appeared
        document
          .querySelector(".manage .energy")
          ?.setAttribute("data-guide", "active");
      },
      () => {
        //Slide Balance Content #2 appeared
        openModal("deposit");
        setTimeout(() => {
          document
            .querySelector(".m-deposit-input input")
            ?.setAttribute("data-guide", "active");
          document
            .querySelector(".m-deposit-add")
            ?.setAttribute("data-guide", "active");
        }, 10);
      },
    ],
    [
      () => {
        //Slide History appeared;
        document
          .querySelector(".manage .history")
          ?.setAttribute("data-guide", "active");
      },
    ],
    [
      () => {
        //Slide Admin appeared;
        document
          .querySelector(".manage .user .p-btn")
          ?.setAttribute("data-guide", "active");
      },
      () => {
        //Slide Admin Content #2 appeared
        setIsUserMenuOpen(true);
        document
          .querySelector(".manage .user")
          ?.setAttribute("data-guide", "active");
      },
    ],
    [
      () => {
        //Slide Configuration appeared
        document
          .querySelector(".extra-menu .settings")
          ?.setAttribute("data-guide", "active");
      },
    ],
    [
      () => {
        //Slide Accounts appeared
        const linkElement = document.querySelector("#accountsLink");
        linkElement.setAttribute("data-guide", "active");
        linkElement.click();
      },
    ],
    [
      () => {
        //Slide Sessions appeared
        const linkElement = document.querySelector("#sessionsLink");
        linkElement.setAttribute("data-guide", "active");
        linkElement.click();
      },
    ],
    [
      () => {
        //Slide Partners appeared
        if (role === "AGENT") {
          const linkElement = document.querySelector("#partnersLink");
          linkElement.setAttribute("data-guide", "active");
          linkElement.click();
        }
      },
    ],
    [],
  ];

  const onDisappearFunctions = [
    [],
    [
      () => {
        //Slide Balance disappeared
        document.querySelector(".manage .energy")?.removeAttribute("data-guide");
      },
      () => {
        //Slide Balance Content #2 disappeared
        document
          .querySelector(".m-deposit-input input")
          ?.removeAttribute("data-guide");
        document.querySelector(".m-deposit-add")?.removeAttribute("data-guide");
        closeModal();
      },
    ],
    [
      () => {
        //Slide History disappeared
        document.querySelector(".manage .history")?.removeAttribute("data-guide");
      },
    ],
    [
      () => {
        //Slide Admin disappeared
        document
          .querySelector(".manage .user .p-btn")
          ?.removeAttribute("data-guide");
      },
      () => {
        //Slide Admin Content #2 disappeared"
        setIsUserMenuOpen(false);
        document.querySelector(".manage .user")?.removeAttribute("data-guide");
      },
    ],
    [
      () => {
        //Slide Configuration disappeared
        document
          .querySelector(".extra-menu .settings")
          ?.removeAttribute("data-guide");
      },
    ],
    [
      () => {
        //Slide Accounts disappeared
        document.querySelector("#accountsLink")?.removeAttribute("data-guide");
      },
    ],
    [
      () => {
        //Slide Sessions disappeared
        document.querySelector("#sessionsLink")?.removeAttribute("data-guide");
      },
    ],
    [
      () => {
        //Slide Partners disappeared
        if (role === "AGENT") {
          document.querySelector("#partnersLink")?.removeAttribute("data-guide");
        }
      },
    ],
    [],
  ];

  useEffect(() => {
    // Вызов функции при появлении слайда
    if (onAppearFunctions[activeTitleIndex][activeSlideIndex]) {
      onAppearFunctions[activeTitleIndex][activeSlideIndex]();
    }

    return () => {
      // Вызов функции при исчезновении слайда
      if (onDisappearFunctions[activeTitleIndex][activeSlideIndex]) {
        onDisappearFunctions[activeTitleIndex][activeSlideIndex]();
      }
    };
  }, [activeTitleIndex, activeSlideIndex]);

  return (
    <div className="modal-guide">
      <div className="guide-title">
        <div className="guide-titles">
          {canGoBackTitle && (
            <div className="prev-title" onClick={handleBackTitleClick}>
              <span>{titles[activeTitleIndex - 1]?.[0]}</span>
            </div>
          )}
          <div className="active-title">{titles[activeTitleIndex][0]}</div>
          {canGoNextTitle && (
            <div className="next-title" onClick={handleNextTitleClick}>
              <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {titles[activeTitleIndex + 1]?.[0]}
              </span>
            </div>
          )}
        </div>
        <div
          onClick={closeGuideModal}
          className={`window_close ${
            !isCloseButtonVisible ? "next-title-hover" : ""
          }`}
        >
          <SvgClose />
        </div>
      </div>
      <div className="guide-content">{getTitleContent()}</div>
      <div className="guide-controls">
        <div className="g-prev-btn">
          {canGoBackSlide && <div onClick={handleBackSlideClick}>{t("back")}</div>}
        </div>
        <div className="g-dots">
          {titles[activeTitleIndex][1].length > 1 &&
            titles[activeTitleIndex][1].map((slide, index) => (
              <div
                key={index}
                className={`g-dot ${
                  activeSlideIndex === index ? "g-dot-active" : ""
                }`}
              />
            ))}
        </div>
        <div className="g-next-btn">
          {canGoNextSlide && <div onClick={handleNextSlideClick}>{t("next")}</div>}
        </div>
      </div>
    </div>
  );
};

export default GuideModal;
