const accountsTranslations = {
  en: {
    accs: "Accounts",
    notes: "Notes",
    onOff: "On/Off",
    room: "Room",
    state: "State",
    online: "Online",
    offline: "Offline",
    type: "Type",
    mode: "Mode",
    level: "Level",
    status: "Status",
    hands: "Hands",
    win: "Win",
    rake: "Rake",
    insur: "Insur",
    errs: "Errs",
    version: "Version",
    lastGame: "Last game",
    deviceInfo: "Device info",
  },
  zh: {
    accs: "账号",
    notes: "标记",
    onOff: "开/关",
    room: "平台",
    state: "状态",
    online: "在线",
    offline: "离线",
    type: "形式",
    mode: "状态",
    level: "级别",
    status: "动态",
    hands: "手",
    win: "战绩",
    rake: "抽水",
    insur: "保险",
    errs: "误差",
    version: "版本",
    lastGame: "最后局",
    deviceInfo: "设备信息",
  },
};

export default accountsTranslations;
