import { useMemo } from "react";

import { formatNumberWithSpaces } from "../../shared/utils/utils";
import { useSessionStore } from "../../store/SessionStore";

export function useSessionsDefaultFiltersOptions() {
  // fixme current or root?
  const rootUser = useSessionStore((session) => session?.data);
  return useMemo(() => {
    return {
      partIds: {
        option: {
          value: rootUser?.partId,
          label: formatNumberWithSpaces(rootUser?.partnerName),
        },
        filter: rootUser?.partId,
      },
      dateStarted: {
        filter: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
      },
      dateEnded: {
        filter: new Date(),
      },
    };
  }, [rootUser?.partId, rootUser?.partnerName]);
}
