import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useUserLogOut } from "../../entities/user/use-user-log-out";
import { useSessionStore } from "../../store/SessionStore";

export function useWsEventsProcessing(webSocket) {
  const { t } = useTranslation("header");
  const userLogOut = useUserLogOut();
  const session = useSessionStore();

  // https://asiateam.youtrack.cloud/articles/PokerEye-A-2/Android-prilozhenie#vebsoketnye-sobytiya-prihodyashie-v-interfejs
  return useEffect(() => {
    switch (webSocket?.lastJsonMessage?.event) {
      case "PARTNER_KEY_CHANGED": {
        userLogOut({ message: `${t("PARTNER_KEY_CHANGED")}.` })?.then(() => {
          //
        });
        break;
      }
      case "DELETE": {
        if (webSocket?.lastJsonMessage?.partId) {
          userLogOut({ message: `${t("DELETE_PARTID_ACCID")}.` })?.then(() => {
            //
          });
        }
        if (session?.accId && session?.accId === webSocket?.lastJsonMessage?.accId) {
          userLogOut({ message: `${t("DELETE_PARTID_ACCID")}.` })?.then(() => {
            //
          });
        }
        break;
      }
      default:
    }
  }, [
    session,
    t,
    userLogOut,
    webSocket?.lastJsonMessage,
    webSocket?.lastJsonMessage?.event,
  ]);
}
