const partnersTranslations = {
  en: {
    partner: "Partner",
    notes: "Notes",
    accs: "Accs",
    fuelBilled: "Fuel Billed",
    hands: "Hands",
    win: "Win",
    rake: "Rake",
    insur: "Insur",
    errs: "Errs",
    fuelBalance: "Fuel Balance",
    fuelOperations: "Fuel Operations",
    history: "History",
    apply: "Apply",
    addPartner: "Add partner",
    addSlot: "Add slot",
    inOut: "In-Out",
    topUp: "Top Up",
    myBalance: "My balance",
    partnerBalance: "Partner balance",
    fuelAmount: "FUEL AMOUNT",
    date: "Date",
    fuel: "Fuel",
  },
  zh: {
    partner: "用户",
    notes: "标记",
    accs: "账号",
    fuelBilled: "燃料消耗",
    hands: "手",
    win: "战绩",
    rake: "抽水",
    insur: "保险",
    errs: "误差",
    fuelBalance: "燃料余额",
    fuelOperations: "燃料动作",
    history: "历史",
    apply: "申请",
    addPartner: "加用户",
    addSlot: "添加插槽",
    inOut: "进-出",
    topUp: "补足",
    myBalance: "我的余额",
    partnerBalance: "用户余额",
    fuelAmount: "燃料量",
    date: "日期",
    fuel: "燃料",
  },
};

export default partnersTranslations;
