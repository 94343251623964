const deviceInfoTranslations = {
  en: {
    osType: "OS type",
    osVersion: "OS version",
    deviceModel: "Device model",
    phoneName: "Phone name",
    longitude: "Longitude",
    latitude: "Latitude",
    battery: "Battery",
    save: "Save",
  },
  zh: {
    osType: "OS 类型",
    osVersion: "OS版本",
    deviceModel: "设备名称",
    phoneName: "手机名称",
    longitude: "经度",
    latitude: "纬度",
    battery: "电量",
    save: "保存",
  },
};

export default deviceInfoTranslations;
