import useWebSocket from "react-use-websocket";

import { useWsEventsProcessing } from "./use-ws-events-processing";

export function useWsEvents({ ...props } = {}) {
  const webSocket = useWebSocket("/api/ws-events-emitter", {
    share: true,
    retryOnError: true,
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
    onOpen: (event) => console.log("xxxxxxxxxxxxxxxxx socket opened ", event),
    onClose: (event) => console.log("xxxxxxxxxxxxxxxxx socket closed ", event),
    onError: (event) => console.log("xxxxxxxxxxxxxxxxx socket error ", event),
    ...props,
  });
  useWsEventsProcessing(webSocket);
  return webSocket;
}
