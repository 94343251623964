import { css } from "@emotion/react";
import React, { useCallback, useMemo } from "react";

import { useConfigStore } from "../../store/ConfigStore";

export function GroupSaveSwitch({ configItem, disabled, ...props }) {
  const { groupsToSave, addGroupToSave, removeGroupToSave, resetUserValues } =
    useConfigStore();

  const checked = useMemo(() => {
    return groupsToSave?.includes(configItem?.confId);
  }, [configItem?.confId, groupsToSave]);

  const onChange = useCallback(
    (event) => {
      if (event?.target?.checked) {
        addGroupToSave(configItem?.confId);
      } else {
        // fixme move to method below
        removeGroupToSave(configItem?.confId);
        resetUserValues(configItem?.confId);
      }
    },
    [addGroupToSave, configItem?.confId, removeGroupToSave, resetUserValues]
  );

  return (
    <input
      className="on-off"
      type="checkbox"
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      css={css`
        &&& {
          cursor: ${disabled ? "default" : "pointer"};
        }
      `}
      {...props}
    />
  );
}
