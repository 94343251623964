import { create } from "zustand";

import { api } from "../api/requests";
import { parseJsonStringToArray, parsedFuelHistory } from "../shared/utils/utils";

export const useHeaderStore = create((set, get) => ({
  currentUser: null,
  balance: null,
  balanceHistory: null,
  allowedRooms: [],
  setCurrentUser: (user) => set({ currentUser: user }),
  getAllowedRoomsReq: async () => {
    const partId = get().currentUser;
    try {
      const response = await api.getAllowedRoomsReq({ partId: partId });
      const parsedData = response.data;
      set({ allowedRooms: parsedData.allowedRooms });
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  getAllowedRooms: () => get().allowedRooms,
  fetchBalance: async () => {
    const partId = get().currentUser;
    try {
      const response = await api.getFuelBalanceReq({ partIds: [partId] });
      const parsedData = response.data;
      if (parsedData.status === "SUCCESS") {
        set({ balance: parsedData.fuelQtys[partId] });
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  fetchFuelHistory: async (partId) => {
    try {
      const response = await api.fuelHistoryReq({ srcPartId: partId });
      const parsedData = parseJsonStringToArray(response.data);
      set({ balanceHistory: parsedFuelHistory([parsedData]) });
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  resetBalanceHistory: () => {
    set({ balanceHistory: null });
  },
  setPartnerSelection: async () => {
    const partId = get().currentUser;
    try {
      const response = await api.setPartnerSelectionReq({ partId: partId });
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  editTgNickname: async (nick) => {
    const partId = get().currentUser;
    try {
      const response = await api.editTelegramReq({
        partId: partId,
        nick: `${nick}`,
      });
      const parsedData = response.data;
      if (parsedData.status === "SUCCESS") {
        return parsedData.status;
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  getCurrentBalance: () => get().balance,
}));
