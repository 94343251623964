import React, { useCallback, useMemo } from "react";

import DateInput from "../../components/DateInput/DateInput";

export function FilterDatePicker({
  name,
  defaultValue,
  searchParams,
  setSearchParam,
  setSearchParams,
  onSelect,
  resetOthers,
  ...props
}) {
  // const { searchParams, setSearchParam } = useRouteUtils();

  const storeValue = useMemo(() => searchParams?.[name], [name, searchParams]);

  const $onSelect = useCallback(
    (value) => {
      if (resetOthers) {
        setSearchParams({
          [name]: value,
        });
        return;
      }
      setSearchParam(name, value);
      if (typeof onSelect === "function") {
        onSelect?.(value);
      }
    },
    [name, onSelect, resetOthers, setSearchParam, setSearchParams]
  );

  return <DateInput selectedDate={storeValue} onSelect={$onSelect} {...props} />;
}
