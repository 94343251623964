const guideTranslations = {
  en: {
    back: "Back",
    next: "Next",
    balance: "Balance",
    toReplenishFuelPressThisButton: "To replenish fuel press this button.",
    getReadyToReplenishTheBalanceThroughACryptoWalletInCurrencyUSDT:
      "Get ready to replenish the balance through a crypto wallet in currency USDT.",
    inThisWindowYouSpecifyTheTopUpAmount:
      "In this window, you specify the top-up amount. Depending on the amount, we will give you a greater quantity of fuel as a bonus.",
    whenYouClickOnTheGiftAddButton:
      "When you click on the gift add button, both the contribution amount and the quantity of bonus fuel will increase for you.",
    history: "History",
    pleaseClickOnThisButtonToViewTheTopUpHistoryAndTransferHistory:
      "Please click on this button to view the top-up history and transfer history.",
    admin: "Admin",
    byClickingOnThisButtonYouCanChangeTheAdministratorSettings:
      "By clicking on this button, you can change the administrator settings - interface, language, download APK, configure Telegram, start the guide, and log out of your account.",
    switchBetweenLightAndDarkMode: "Switch between light and dark mode.",
    changeTheLanguage: "Change the language.",
    downloadTheAPK: "Download the APK.",
    setYourTelegramNicknameInTheAPKForPartners:
      "Set your Telegram nickname in the APK for partners.",
    startTheGuideReplay: "Start the guide replay.",
    logOutOfYourAccount: "Log out of your account.",
    configuration: "Configuration",
    thisWindowIsDesignedForAccountConfiguration:
      "This window is designed for account configuration, where you can select a club, game type, and mode, and customize them according to your preferences.",
    accounts: "Accounts",
    accountManagementWindow1:
      "Account management window. Here you can update APK, modify device info settings, and by clicking on the activity indicators ",
    accountManagementWindow2:
      "in the left part of the table, you can quickly configure your accounts there",
    sessions: "Sessions",
    inThisWindowYouCanViewYourSessions:
      "In this window, you can view your sessions. For better control of your achievements, please configure your account name, club, game type, and session time interval in advance.",
    partners: "Partners",
    retail: "Retail",
    forVIPpartners: "For VIP partners only.",
    hereYouWillBeAbleToAddNewPartners:
      "Here, you will be able to add new partners, view their statistics by selecting specific time intervals on the calendar, write notes for each partner to facilitate your orientation, manage their fuel, and review the top-up history for each partner individually.",
  },
  zh: {
    back: "后",
    next: "前",
    balance: "燃料余额",
    toReplenishFuelPressThisButton: "要补充燃料，请按此按钮",
    getReadyToReplenishTheBalanceThroughACryptoWalletInCurrencyUSDT:
      "准备好通过货币USDT的加密钱包补充余额",
    inThisWindowYouSpecifyTheTopUpAmount:
      "在此窗口中你指定充值金额。多买燃料我们可以我赠送燃料",
    whenYouClickOnTheGiftAddButton:
      "点赠送按钮后，供款金额和奖励燃料的数量都会为你增加",
    history: "历史",
    pleaseClickOnThisButtonToViewTheTopUpHistoryAndTransferHistory:
      "请点击此按钮查看充值历史和转账历史",
    admin: "Admin",
    byClickingOnThisButtonYouCanChangeTheAdministratorSettings:
      "通过单击此按钮，你可以更改管理员设置-界面，语言，下载APK，配置Telegram，运行说明并注销你的帐户",
    switchBetweenLightAndDarkMode: "明暗模式之间切换",
    changeTheLanguage: "选语言",
    downloadTheAPK: "下载APK",
    setYourTelegramNicknameInTheAPKForPartners: "配给软件里面的Telegram 账户",
    startTheGuideReplay: "重播说明",
    logOutOfYourAccount: "退出你的账户",
    configuration: "设置",
    thisWindowIsDesignedForAccountConfiguration:
      "此窗口用于帐户配置，你可以在其中选择俱乐部，游戏类型和模式，并根据你的需要的自定义",
    accounts: "账号",
    accountManagementWindow1:
      "帐户管理窗口可以更新APK，修改设备信息设置，并通过单击活动指示器",
    accountManagementWindow2: "在表格的左侧，可以在快速配置你的帐户",
    sessions: "期间",
    inThisWindowYouCanViewYourSessions:
      "在此窗口中你可以查看期间。 为了更好地控制您的成就请提前配置你的帐户名称，俱乐部，游戏类型和期间",
    partners: "用户",
    retail: "零售",
    forVIPpartners: "仅限 VIP 合作伙伴",
    hereYouWillBeAbleToAddNewPartners:
      "在这里，你能添加新的用户。通过在日历上选择特定的时间间隔来查看他们的统计信息。为每个用户写笔记，管理他们的燃料，单独查看每个用户的充值历史",
  },
};

export default guideTranslations;
