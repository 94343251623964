import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.scss";
import { router } from "./Router";
import { api } from "./api/requests";
import { ApiContext } from "./context/context";
import "./simplebar.css";
import { useSessionStore } from "./store/SessionStore";

function App() {
  const queryClient = new QueryClient();
  const darkModeCookie = Cookies.get("isDarkMode");
  const theme = useSessionStore().theme;

  useEffect(() => {
    if (darkModeCookie === "1") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkModeCookie]);

  return (
    <QueryClientProvider client={queryClient}>
      <ApiContext.Provider value={api}>
        <div className="App">
          <RouterProvider router={router} />
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={theme}
          transition={Slide}
          style={{ zIndex: 99999 }}
        />
      </ApiContext.Provider>
    </QueryClientProvider>
  );
}

export const authEventTarget = new EventTarget();

authEventTarget.addEventListener("unauthorized", () => {
  const sessionStore = useSessionStore.getState();
  sessionStore.resetUserData();
  return router.navigate("/login");
});

export default App;
//
