import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { usePrevious } from "react-use";
import SimpleBar from "simplebar-react";

import { useSessionsDefaultFiltersOptions } from "../../entities/session/use-sessions-default-filters-options";
import { smoothScrollToBottom } from "../../shared/utils/utils";
import "../../simplebar.css";
import { useHeaderStore } from "../../store/HeaderStore";
import { usePartnersStore } from "../../store/PartnersStore";
import { useSessionStore } from "../../store/SessionStore";
import { PartnersTableRow } from "./PartnersTableRow";
import { PartnersDateEnded } from "./filters/partners-date-ended";
import { PartnersDateStarted } from "./filters/partners-date-started";
import { PartnersPartId } from "./filters/partners-part-id";

function PartnersPage() {
  const { t } = useTranslation("partners");
  const simpleBarRef = useRef(null);
  const sessionStore = useSessionStore();
  const headerStore = useHeaderStore();
  const partnersStore = usePartnersStore();

  const {
    loading,
    createLoading,
    searchParams,
    setSearchParam,
    setSearchParams,
    fetchPartners,
  } = partnersStore;

  const scrollToBottom = () => {
    const scrollElement = simpleBarRef.current.getScrollElement();
    smoothScrollToBottom(scrollElement);
  };

  const onAddPartner = () => {
    const param =
      sessionStore.role === "AGENT"
        ? sessionStore.data.contact
        : headerStore.currentUser;
    partnersStore.createPartner(sessionStore.role, param).then(() => {
      scrollToBottom();
      sessionStore.getSubpartnersReq();
    });
  };

  // fixme extract
  const defaults = useSessionsDefaultFiltersOptions();

  useEffect(() => {
    if (!searchParams?.partIds) {
      setSearchParams({
        // fixme refactor defaults
        partIds: defaults?.partIds?.filter,
        dateStarted: defaults?.dateStarted?.filter,
        dateEnded: defaults?.dateEnded?.filter,
      });
      return;
    }
  }, [defaults, searchParams?.partIds, setSearchParams]);

  const currentUser = sessionStore.getCurrentUser();

  const prevCurrentUser = usePrevious(currentUser);
  useEffect(() => {
    if (prevCurrentUser && currentUser !== prevCurrentUser) {
      setSearchParams({
        partIds: currentUser,
      });
    }
  }, [currentUser, prevCurrentUser, searchParams?.partIds, setSearchParams]);

  useEffect(() => {
    if (searchParams?.partIds) {
      fetchPartners({
        role: sessionStore?.role,
      });
    }
  }, [fetchPartners, sessionStore?.role, searchParams]);

  return (
    <SimpleBar ref={simpleBarRef} className="content partners">
      <div style={{ position: "relative" }}>
        <div className="table-filters">
          <div className="table-filters-l">
            <PartnersPartId
              name="partIds"
              placeholder="Partner"
              disabled={loading}
            />
            <PartnersDateStarted disabled={loading} />
            <PartnersDateEnded disabled={loading} />
          </div>
          <div className="table-filters-r">
            <div
              onClick={onAddPartner}
              className={`p-btn grayBg ${createLoading && "loading"}`}
            >
              {createLoading
                ? ""
                : sessionStore.role === "AGENT"
                ? t("addPartner")
                : t("addSlot")}
            </div>
          </div>
        </div>
        {/* TODO обработать начальное состояние и случай если по запросу нет данных */}
        <div className="content-table">
          {partnersStore.partners.length ? (
            <div className="table-th">
              <div>#</div>
              <div>{t("partner")}</div>
              <div>{t("notes")}</div>
              {sessionStore.role === "AGENT" && <div>{t("accs")}</div>}
              <div>{t("fuelBilled")}</div>
              <div>{t("hands")}</div>
              <div>{t("win")}</div>
              <div>{t("rake")}</div>
              <div>{t("insur")}</div>
              <div>{t("errs")}</div>
              <div>{t("fuelBalance")}</div>
              <div className="fuel-operations">{t("fuelOperations")}</div>
              <div className="partner-history">{t("history")}</div>
            </div>
          ) : (
            ""
          )}
          {partnersStore.partners.length
            ? partnersStore.partners
                // .sort((a, b) => (a.partnerName).localeCompare((b.partnerName), 'en', {sensitivity: 'base'}))
                .map((item, index) => (
                  <PartnersTableRow
                    key={item.partId}
                    index={index}
                    partnerName={item.partnerName}
                    notes={item.notes}
                    accs={item.totalAccounts}
                    fuelBilled={item.fuelBilled}
                    hands={item.totalHands}
                    win={item.totalWinLose}
                    rake={item.totalRake}
                    insur={item.totalInsurance}
                    errors={item.totalErrors}
                    fuelBalance={item.totalFuel}
                    authKey={item.authKey}
                    partnerId={item.partId}
                  />
                ))
            : ""}
        </div>
      </div>
    </SimpleBar>
  );
}

export default PartnersPage;
