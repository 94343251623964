const sessionsTranslations = {
  en: {
    accounts: "Accounts",
    pid: "PID",
    room: "Room",
    aid: "AID",
    cid: "CID",
    type: "Type",
    sessionTime: "Session Time",
    fuelBilled: "Fuel Billed",
    hands: "Hands",
    level: "Level",
    win: "Win",
    rake: "Rake",
    insur: "Insur",
    errors: "Errs",
    server: "Server",
    empty: "Empty",
    active: "Active",
    downloadCSV: "Download CSV",
    apply: "Apply",
  },
  zh: {
    accounts: "账号",
    pid: "游戏账号",
    room: "平台",
    aid: "联盟号",
    cid: "俱乐部号",
    type: "形式",
    sessionTime: "期间",
    fuelBilled: "燃料消耗",
    hands: "手",
    level: "级别",
    win: "战绩",
    rake: "抽水",
    insur: "保险",
    errors: "误差",
    server: "服务器",
    empty: "空",
    active: "运行中",
    downloadCSV: "下载CSV",
    apply: "申请",
  },
};

export default sessionsTranslations;
