import { css } from "@emotion/react";
import React, { createRef, useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";

import { ReactComponent as SvgSelect } from "../images/select.svg";
import "../simplebar.css";

export const Select = ({
  options,
  onSelect,
  selected,
  disabled,
  gray,
  totalPrice,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownOptionRefs = useRef(options.map(() => createRef()));
  const inputRef = useRef(null);

  useEffect(() => {
    dropdownOptionRefs.current = options.map(() => createRef());
  }, [options]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setInputValue("");
    if (!isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleOptionSelect = (option) => {
    onSelect(option);
    setIsOpen(false);
    setInputValue(option.label);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setIsInputFocused(false); // Set isInputFocused to false when clicking outside
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const filteredOptions = options.filter(
    (option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase()) &&
      option.value !== selected?.value
  );

  const maxHeight = 200;

  useEffect(() => {
    const dropdownElement = dropdownRef.current;
    const dropdownOptionElement = dropdownOptionRefs.current[0]?.current;
    const selectedOptionElement =
      dropdownElement?.querySelector(".dropdown-selected");

    if (
      isOpen &&
      dropdownElement &&
      dropdownOptionElement &&
      selectedOptionElement
    ) {
      const selectedOptionHeight = selectedOptionElement.offsetHeight;
      const optionHeight = dropdownOptionElement.offsetHeight;
      const optionsCount = filteredOptions.length;
      let newDropdownHeight = selectedOptionHeight + optionHeight * optionsCount + 6;

      if (newDropdownHeight > selectedOptionHeight + maxHeight) {
        newDropdownHeight = selectedOptionHeight + maxHeight + 6;
      }

      dropdownElement.style.height = `${newDropdownHeight}px`;
    } else {
      if (dropdownElement) {
        dropdownElement.style.height = "";
      }
    }
  }, [isOpen, filteredOptions]);

  const handleInputFocus = () => {
    setIsInputFocused(true);
    setInputValue("");
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
    if (!isOpen) {
      setInputValue(selected?.label || "");
    }
  };

  return (
    <div
      className={`select-wrap ${isOpen ? "active" : ""} ${
        disabled ? "disable-select" : ""
      }`}
      css={css`
        &&& {
          opacity: ${disabled ? "0.5" : "1"};
        }
      `}
    >
      <div
        className={`select-dropdown ${gray ? "graySelect" : ""}`}
        onClick={() => {
          if (disabled !== true) {
            toggleDropdown();
          }
        }}
        ref={dropdownRef}
      >
        <div className={`dropdown-selected  ${selected?.icon ? "withIcon" : ""}`}>
          <input
            type="text"
            value={
              !isOpen && !isInputFocused
                ? selected?.label.toUpperCase() ?? ""
                : inputValue
            }
            onChange={handleInputChange}
            placeholder={selected?.label.toUpperCase() || "SEARCH"}
            className="dropdown-search"
            ref={inputRef}
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
              if (disabled !== true) {
                setIsOpen(true);
              }
            }}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            {...props}
          />
          {selected?.hint && (
            <span className="selected-hint">+{selected?.hint}%</span>
          )}
          {totalPrice !== undefined && totalPrice > 0 ? (
            <span className="selected-hint totalPrice">+{totalPrice}%</span>
          ) : (
            ""
          )}
          <SvgSelect />
          {selected?.icon && (
            <img src={selected?.icon} className="dropdown-icon" alt="" />
          )}
        </div>
        {(isOpen || isInputFocused) && (
          <SimpleBar style={{ maxHeight: maxHeight }}>
            <ul className="dropdown-options">
              {filteredOptions.map((option, index) => (
                <li
                  key={option.value}
                  onClick={() => handleOptionSelect(option)}
                  ref={dropdownOptionRefs.current[index]}
                  className={`dropdownOptionsItem ${
                    option.icon ? "optionWithIcon" : ""
                  }`}
                >
                  <div
                    className={`dropdown-option-label ${
                      option.icon ? "optionWithIcon" : ""
                    } `}
                  >
                    {option.label}{" "}
                    {option?.hint && (
                      <span className="select-hint">+{option?.hint}%</span>
                    )}
                    {option?.icon && (
                      <img src={option?.icon} className="optionIcon" alt="" />
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </SimpleBar>
        )}
      </div>
    </div>
  );
};

export default Select;
