const configDescriptionTranslation = {
  en: {
    rebuy_trigger: "Do rebuy when stack is less than this value (in BBs)",
    rebuy_amount: "in BBs",
    rebuy_step: "in BBs",
    exit_lifetime: "in minutes",
    timings_bets: "in seconds",
    timings_calls: "in seconds",
    timings_checks: "in seconds",
    timings_folds: "in seconds",
    timings_raises: "in seconds",
    limit: "set limit at 100 BB",
    buyin_amount: "in BBs",
    buyin_step: "in BBs",
    table_lifetime: "in minutes",
    timings_insur_buy: "in seconds",
    timings_insur_fold: "in seconds",
    scheduler_play_time: "in hours",
    tips_lifetime: "in seconds",
  },
  zh: {
    rebuy_trigger: "当筹码少于此值时重新购买（以BB为单位）",
    rebuy_amount: "按BB 计算",
    rebuy_step: "按BB 计算",
    exit_lifetime: "按分钟计算",
    timings_bets: "按秒计算",
    timings_calls: "按秒计算",
    timings_checks: "按秒计算",
    timings_folds: "按秒计算",
    timings_raises: "按秒计算",
    limit: "将级别设置为 100 BB",
    buyin_amount: "按BB 计算",
    buyin_step: "按BB 计算",
    table_lifetime: "按分钟计算",
    timings_insur_buy: "按秒计算",
    timings_insur_fold: "按秒计算",
    scheduler_play_time: "按小时计算",
    tips_lifetime: "按秒计算",
  },
};

export default configDescriptionTranslation;
