import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./i18nConfig";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <App />
    <div id="modal-root"></div>
  </>
);
