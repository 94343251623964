import { css } from "@emotion/react";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";

import { ReactComponent as SvgArrowLeft } from "../../images/arrow-left.svg";
import { ReactComponent as SvgArrowRight } from "../../images/arrow-right.svg";
import { ReactComponent as SvgArrow } from "../../images/calendar-arrow.svg";

const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  changeYear,
  decreaseYear,
  increaseYear,
  showYearDropdown,
  setShowYearDropdown,
}) => {
  const currentYear = new Date().getFullYear(); // Получаем текущий год

  const yearsArray = Array.from({ length: 24 }, (_, i) => currentYear - i); // Формируем массив лет на основе текущего года

  const selectedYear = date.getFullYear();
  const selectedMonth = date.getMonth();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleYearChange = (year) => {
    changeYear(year);
    setShowYearDropdown(false); // Закрываем выпадающий блок при выборе года
  };

  return (
    <div className="custom-header">
      <div className="custom-header-date">
        <span className="react-datepicker-month-text">
          {monthNames[selectedMonth]}
        </span>
        <span
          className="react-datepicker-year-text"
          onClick={() => setShowYearDropdown(!showYearDropdown)}
        >
          {selectedYear}&nbsp;
          <SvgArrow />
        </span>
      </div>
      <div className="react-datepicker-btns">
        <div className="react-datepicker-prev-month" onClick={decreaseMonth}>
          <SvgArrowLeft />
        </div>
        <div className="react-datepicker-next-month" onClick={increaseMonth}>
          <SvgArrowRight />
        </div>
      </div>
      {showYearDropdown && (
        <div className="year-dropdown-wrap year-selection">
          <div className="year-dropdown">
            {yearsArray.map((year) => (
              <div
                key={year.toString()}
                onClick={() => handleYearChange(year)}
                className={selectedYear === year ? "active-year" : ""}
              >
                {year}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const DateInput = ({
  placeholderText,
  id,
  selectedDate,
  onSelect,
  disabled = false,
}) => {
  const [showYearDropdown, setShowYearDropdown] = useState(false);

  const handleCalendarClose = () => {
    setShowYearDropdown(false); // Закрываем выпадающий блок при закрытии календаря
  };
  // TODO сейчас подсвечивается текущее число на всех месяцах
  return (
    <div
      className="date-input-container"
      css={css`
        &&& {
          opacity: ${disabled ? "0.5" : "1"};
        }
      `}
    >
      <DatePicker
        disabled={disabled}
        selected={selectedDate}
        onChange={onSelect}
        dateFormat="dd.MM.yyyy" // Формат отображения даты
        placeholderText={placeholderText} // Передаем значение placeholderText
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={15} // Количество отображаемых лет в выпадающем списке
        renderCustomHeader={(props) => (
          <CustomHeader
            {...props}
            showYearDropdown={showYearDropdown}
            setShowYearDropdown={setShowYearDropdown}
          />
        )}
        onCalendarClose={handleCalendarClose} // Обработчик события при закрытии календаря
        // Обработчик события при открытии календаря
        isClearable
        formatWeekDay={(date) => date.substring(0, 3)}
        id={id} // Передаем значение id
        customInput={<InputMask mask="99.99.9999" maskChar="_" autoComplete="off" />}
        popperClassName={showYearDropdown ? "year-selection" : ""} // Применяем класс всплывающего окна при его открытии
      />
    </div>
  );
};

export default DateInput;
