import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";

import DeviceModal from "../../components/modal/DeviceModal";
import { useUserRole } from "../../entities/user/use-user-role";
import { formatNumberWithSpaces } from "../../shared/utils/utils";
import { useAccountsFormStore } from "../../store/AccountsFormStore";
import { useAccountsStore } from "../../store/AccountsStore";
import { useSessionStore } from "../../store/SessionStore";

export const AccountsTableRow = ({
  accId,
  index,
  account,
  notes = "",
  room,
  type,
  mode,
  status,
  hands,
  win,
  rake,
  insur,
  errors,
  version,
  lastGame,
  deviceInfo,
  state,
  lastVersion,
  ...row
}) => {
  const [note, setNote] = useState(notes);
  const [editingNote, setEditingNote] = useState(false);
  const { t } = useTranslation("accounts");
  const partId = useSessionStore().currentUser;
  const accountsStore = useAccountsStore();

  const updateNote = (accId, newNote) => {
    accountsStore.updateNote(accId, newNote).then(() => {
      setEditingNote(false);
      accountsStore.fetchAccounts(partId);
    });
    // setEditingNote(false)
    console.log("partId: ", partId, "accId:", accId, " НОВАЯ ЗАМЕТКА: ", newNote); // TODO Добавить запрос на обновление Notes
  };

  const [selectedModal, setSelectedModal] = useState(null);

  const openModal = (modalName) => {
    setSelectedModal(modalName);
  };

  const closeModal = () => {
    setSelectedModal(null);
    document.body.classList.remove("ReactModal__Body--open");
  };
  Modal.setAppElement("#root");

  const level = useMemo(() => {
    const value = parseInt(row?.level);
    return isNaN(value) ? "-" : value;
  }, [row?.level]);

  const role = useUserRole();
  const isAgentOrSubagent = role === "AGENT" || role === "SUBAGENT";

  return (
    <>
      <div className="table-tr">
        <div>{index + 1}</div>
        <div>
          <input
            type="checkbox"
            className="select-item"
            checked={
              accountsStore.getSelectedAccounts().find((item) => item === accId) ||
              false
            }
            onChange={() => {
              accountsStore.handleToggleAccount(accId);
            }}
          />
        </div>
        <div>{formatNumberWithSpaces(account)}</div>
        <div className="editable-note">
          {editingNote ? (
            // fixme extract
            <input
              type="text"
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
              }}
              onBlur={() => updateNote(accId, note)}
              onKeyDown={(event) => {
                if (event?.key === "Enter") {
                  updateNote(accId, note);
                }
              }}
              maxLength={8}
              autoFocus
            />
          ) : (
            <span onClick={() => setEditingNote(true)}>{note}</span>
          )}
        </div>
        <div>{room}</div>
        <div>
          <div className="indicator-wrapper">
            <div className={`indicator ${state ? "" : "disabled-indicator"}`} />
            {state ? t("online") : t("offline")}
          </div>
        </div>
        <div>{type}</div>
        <div>{mode}</div>
        <div>{level}</div>
        <div>{status}</div>
        <div>{hands?.toLocaleString()}</div>
        <div>{win?.toLocaleString()}</div>
        <div>{rake?.toLocaleString()}</div>
        <div>{insur?.toLocaleString()}</div>
        <div>{errors?.toLocaleString()}</div>
        <div>{version}</div>
        <div>{lastGame}</div>
        {!isAgentOrSubagent && (
          <div>
            <span className="dev-info" onClick={() => openModal("device")}>
              {deviceInfo}
            </span>
          </div>
        )}
      </div>
      <Modal
        isOpen={selectedModal === "device"}
        onRequestClose={() => {
          closeModal();
          useAccountsFormStore.setState({ formValues: null });
        }}
        closeTimeoutMS={300}
        className={`modal ${selectedModal === "device" ? "closing-effect" : ""}`}
        overlayClassName="modal-overlay"
      >
        <DeviceModal accId={accId} closeModal={closeModal} />
      </Modal>
    </>
  );
};
