import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import SimpleBar from "simplebar-react";

import { ReactComponent as SvgClose } from "../../images/close.svg";
import { formatDateFull, formatNumberWithSpaces } from "../../shared/utils/utils";
import { useHeaderStore } from "../../store/HeaderStore";
import { useSessionStore } from "../../store/SessionStore";

const HistoryModal = ({ closeModal }) => {
  const { t } = useTranslation("header");
  const currentUser = useSessionStore().getCurrentUser();
  const headerStore = useHeaderStore();
  const role = useSessionStore().role;

  useEffect(() => {
    headerStore.fetchFuelHistory(currentUser);
  }, []);

  if (role === "SUBAGENT") {
    return (
      <div className="modal-history modal-history-subagent">
        <div className="window_close" onClick={closeModal}>
          <SvgClose />
        </div>
        <div className="m-history-title">{t("history")}</div>
        <SimpleBar className="m-history-content">
          <div className="content-table">
            <div className="table-th">
              <div>{t("date")}</div>
              <div>{t("fuel")}</div>
            </div>
            {headerStore.balanceHistory?.transferHistory.map((item) => (
              <div key={item.date} className="table-tr">
                <div>{formatDateFull(new Date(item.date * 1000))}</div>
                <div>{item.fuel?.toLocaleString()}</div>
              </div>
            ))}
          </div>
        </SimpleBar>
      </div>
    );
  }

  return (
    <div className="modal-history">
      <div className="window_close" onClick={closeModal}>
        <SvgClose />
      </div>
      <div className="m-history-title">{t("history")}</div>

      <Tabs>
        <TabList>
          <Tab className="p-btn grayBg">{t("topUpHistory")}</Tab>
          <Tab className="p-btn grayBg">{t("transferHistory")}</Tab>
        </TabList>
        <TabPanel>
          <SimpleBar className="m-history-content">
            <div className="content-table">
              <div className="table-th">
                <div>{t("date")}</div>
                <div>{t("usdt")}</div>
                <div>{t("fuel")}</div>
              </div>
              {headerStore.balanceHistory?.topUpHistory.map((item) => (
                <div key={item.date} className="table-tr">
                  <div>{formatDateFull(new Date(item.date * 1000))}</div>
                  <div>{item.usdt?.toLocaleString()}</div>
                  <div>{item.fuel?.toLocaleString()}</div>
                </div>
              ))}
            </div>
          </SimpleBar>
        </TabPanel>
        <TabPanel>
          <SimpleBar className="m-history-content">
            <div className="content-table">
              <div className="table-th">
                <div>{t("date")}</div>
                <div>{t("partner")}</div>
                <div>{t("fuel")}</div>
              </div>
              {headerStore.balanceHistory?.transferHistory.map((item) => (
                <div key={item.date} className="table-tr">
                  <div>{formatDateFull(new Date(item.date * 1000))}</div>
                  <div>{formatNumberWithSpaces(item.partner)}</div>
                  <div>{item.fuel?.toLocaleString()}</div>
                </div>
              ))}
            </div>
          </SimpleBar>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default HistoryModal;
