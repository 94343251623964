import React from "react";

import { useSessionsDefaultFiltersOptions } from "../../../entities/session/use-sessions-default-filters-options";
import { FilterDatePicker } from "../../../shared/ui/filter-date-picker";
import { usePartnersStore } from "../../../store/PartnersStore";

export function PartnersDateEnded({ ...props }) {
  const defaults = useSessionsDefaultFiltersOptions();
  const { searchParams, setSearchParam, setSearchParams } = usePartnersStore();
  return (
    <FilterDatePicker
      // defaultValue={defaults?.dateEnded?.option}
      placeholderText="Date to"
      id="dateEnded"
      name="dateEnded"
      searchParams={searchParams}
      setSearchParam={setSearchParam}
      setSearchParams={setSearchParams}
      {...props}
    />
  );
}
