import { create } from "zustand";

import { api } from "../api/requests";
import {
  formatDateShort,
  formatNumberWithSpaces,
  parseJsonStringToArray,
} from "../shared/utils/utils";

export const useSessionPageStore = create((set, get) => ({
  allowedRooms: null,
  accounts: [{ value: null, label: "All" }],
  sessions: [],
  loading: false,
  currentPage: 0,
  totalPages: 1,
  searchParams: {},
  getAccounts: () => get().accounts,
  getAllowedRooms: async (partId) => {
    set({ loading: true });
    try {
      set({ allowedRooms: null, roomTypes: null });
      const response = await api.availableRoomsAndGameTypesReq({ partId: partId });
      const parsedData = response?.data;
      set({
        allowedRooms: { rooms: parsedData.rooms, roomTypes: parsedData.gameTypes },
      });
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      set({ loading: false });
    }
  },
  fetchAccounts: async (partId) => {
    set({ loading: true });
    try {
      set({ accounts: [{ value: null, label: "All" }] });
      const response = await api.accountsReq({ partId: partId });
      const parsedData = parseJsonStringToArray(response.data);
      const accounts = parsedData.map((item) => ({
        value: item.account.deviceId,
        label: formatNumberWithSpaces(item.account.deviceId),
      }));
      set({ accounts: [{ value: null, label: "All" }, ...accounts] });
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      set({ loading: false });
    }
  },
  fetchSessions: async ({ page = 0 } = {}) => {
    const {
      partIds,
      deviceId,
      room,
      gameType,
      dateStarted,
      dateEnded,
      onlyActive,
      showEmpty,
    } = get()?.searchParams || {};

    if (!partIds) {
      return;
    }

    set({ loading: true });
    try {
      const response = await api.sessionsReq({
        offset: page * 50,
        partIds: [partIds],
        deviceId,
        room,
        gameType,
        dateStarted: formatDateShort(dateStarted),
        dateEnded: formatDateShort(dateEnded),
        onlyActive,
        showEmpty,
      });

      const parsedData = parseJsonStringToArray(response.data);
      const totalPages = parsedData.find(
        (item) => item.name === "pagination"
      ).totalPages;

      set((state) => ({
        sessions:
          page === 0
            ? parsedData.filter((item) => item.name === "session")
            : state.sessions.concat(
                parsedData.filter((item) => item.name === "session")
              ),
        totalPages,
        currentPage: page,
      }));
      return response;
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      set({ loading: false });
    }
  },
  setSearchParams: async (searchParams = {}) => {
    set({ searchParams });
  },
  setSearchParam: async (key, value) => {
    if (typeof key === "string") {
      set(({ searchParams } = {}) => ({
        searchParams: {
          ...searchParams,
          [key]: value,
        },
      }));
    }
  },
}));
